
import Vue from 'vue';
export default Vue.extend({
  computed: {
    hasShelters() {
      return this.$store.getters.hasShelters;
    },
    allowSubmissions(): boolean {
      if (this.$store.getters.count.type !== 'PIT Count') return true;
      if (!this.countCreatedTime) return true;
      const oneYear = (1000 * 3600 * 24 * 365) / 2;
      const oneYearPastCreation = this.countCreatedTime + oneYear;
      const now = new Date();
      return now.getTime() < oneYearPastCreation;

    },
    countCreatedTime() {
      return this.$store?.getters?.count?.created
    }
  },
  methods: {
    next(surveyType: 'unsheltered' | 'sheltered') {
      if (surveyType === 'unsheltered')
        this.$router.push({ path: '/location' });
      else this.$router.push({ path: '/start?sheltered=true' });
    },
  },
});
