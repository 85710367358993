
import Vue from 'vue';
import Scoreboard from './Scoreboard.vue';
import Language from './Language.vue';
import LocationToggle from './LocationToggle.vue';
import Toolbar from './Toolbar.vue';
import StartSurveyButtons from './StartSurveyButtons.vue';
import LoggedIn from './LoggedIn.vue';
import SurveyHistory from './SurveyHistory.vue';
import HomeStartButtons from './Home/HomeStartButtons.vue';
export type SurveyMode = 'Training' | 'Live' | 'Finished' | 'Volunteer Registration';
export default Vue.extend({
  name: 'Home',
  components: {
    Scoreboard,
    LocationToggle,
    Language,
    Toolbar,
    StartSurveyButtons,
    LoggedIn,
    SurveyHistory,
    HomeStartButtons,
  },
  computed: {
    mode(): SurveyMode {
      return this.$store.getters.count.mode;
    },
    underText(): string {
      if (this.mode === 'Training') {
        return 'Training Mode'
      }
      if (this.mode === 'Live') {
        return 'Live'
      }
      if (this.mode === 'Finished') {
        return 'Finished'
      }
      return ''
    },
  },
});
